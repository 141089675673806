import React from "react";
import { graphql, Link } from "gatsby";

import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

import Layout from "@components/Layout";
import Seo from "@components/Seo";

import * as styles from "@styles/components/Blog/post.module.scss";

type Props = {
  data: {
    site: {
      siteMetadata: {
        siteUrl: string;
      };
    };
    mdx: {
      frontmatter: {
        title: string;
        posterImage: {
          publicURL: string;
          childImageSharp: ImageDataLike;
        };
        category: string;
        date: string;
      };
      excerpt: string;
      body: any;
      slug: string;
    };
    relatedMdxs: {
      posts: {
        frontmatter: {
          title: string;
          posterImage: {
            publicURL: string;
            childImageSharp: ImageDataLike;
          };
          category: string;
          date: string;
        };
        excerpt: string;
        slug: string;
      }[];
    };
  };
};

const BlogPost: React.FC<Props> = ({
  data: {
    mdx: {
      body,
      slug,
      frontmatter: { title, posterImage, category, date },
      excerpt,
    },
    relatedMdxs,
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  const postImage = getImage(posterImage.childImageSharp) as IGatsbyImageData;

  return (
    <Layout>
      <Seo
        title={title}
        desc={excerpt}
        image={posterImage.publicURL}
        url={slug}
      />

      <article className={styles.post}>
        <section className={styles.post__details}>
          {category && (
            <Link to={`/category/${category}`} target="_self">
              <span className={styles.category}>{category}</span>
            </Link>
          )}

          <h1 className={styles.post__title}>{title}</h1>

          <time className={styles.post__info} dateTime={date}>
            {date}
          </time>
        </section>

        <GatsbyImage
          image={postImage}
          alt={title}
          className={styles.post__poster}
          imgStyle={{ objectFit: "contain" }}
        />

        <section className={styles.post__content}>
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </article>

      <section className={styles.share}>
        <h2>Share</h2>

        <div>
          <WhatsappShareButton url={`${siteUrl}/blog/${slug}`}>
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
          <FacebookShareButton url={`${siteUrl}/blog/${slug}`}>
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <PinterestShareButton
            url={`${siteUrl}/blog/${slug}`}
            media={`${siteUrl}/${posterImage.publicURL}`}
          >
            <PinterestIcon size={40} />
          </PinterestShareButton>
          <TwitterShareButton url={`${siteUrl}/blog/${slug}`}>
            <TwitterIcon size={40} />
          </TwitterShareButton>
          <EmailShareButton url={`${siteUrl}/blog/${slug}`}>
            <EmailIcon size={40} />
          </EmailShareButton>
          <RedditShareButton url={`${siteUrl}/blog/${slug}`}>
            <RedditIcon size={40} />
          </RedditShareButton>
        </div>
      </section>

      {relatedMdxs && relatedMdxs.posts && relatedMdxs.posts.length > 0 && (
        <section className={styles.related}>
          <h2 className={styles.related__title_top}>Related Posts:</h2>

          <div className={styles.related__wrapper}>
            {relatedMdxs.posts
              .filter((e) => e.frontmatter.posterImage !== null)
              .map((post, index) => {
                if (index > 3) {
                  return;
                }

                const {
                  frontmatter: { title, category, posterImage, date },
                  slug,
                  excerpt,
                } = post;

                const postImage = getImage(
                  posterImage.childImageSharp
                ) as IGatsbyImageData;

                return (
                  <article key={index} className={styles.related__post}>
                    <div className={styles.related__poster}>
                      <Link to={`/blog/${slug}`}>
                        <GatsbyImage
                          image={postImage}
                          alt={title}
                          imgStyle={{ objectFit: "contain" }}
                        />
                      </Link>
                    </div>

                    <div className={styles.related__details}>
                      {category && (
                        <Link to={`/category/${category}`} target="_self">
                          <span className={styles.related__category}>
                            {category}
                          </span>
                        </Link>
                      )}

                      <Link to={`/blog/${slug}`}>
                        <h3 className={styles.related__title}>{title}</h3>
                      </Link>

                      <p className={styles.related__desc}>{excerpt}</p>

                      <time dateTime={date}>{date}</time>
                    </div>
                  </article>
                );
              })}
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(frontmatter: { component_id: { eq: "post" } }, id: { eq: $id }) {
      frontmatter {
        title
        posterImage {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        category
        date(formatString: "MMMM D, YYYY")
      }
      body
      slug
      excerpt
    }
    relatedMdxs(
      parent: { id: { eq: $id } }
      posts: { elemMatch: { frontmatter: { component_id: { eq: "post" } } } }
    ) {
      posts {
        frontmatter {
          title
          posterImage {
            childImageSharp {
              gatsbyImageData(height: 200)
            }
          }
          category
          date(formatString: "MMMM D, YYYY")
        }
        excerpt
        slug
      }
    }
  }
`;

export default BlogPost;
